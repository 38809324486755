import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
const initialState = {
    status: 'idle',
    configFrontTypes: [],
    rentableStatus: [{ id: '', name: '' }],
    seasonOccurences: [],
    seasonOccurence: null,
    paymentTypes: {},
    expenseStatus: [],
    customerIdType: {},
    paymentType: null,
    roles: [],
    reservationStatus: {},
    expenseConfigTypes: {},
    expenseConfigOccurrences: {},
    bankAccountStatus: [],
    bankAccountTypes: [],
    financialSources: [],
    branchCashBoxStatus: [],
    branchCashBoxTypes: [],
};
// ----------------------------------------------------------------------
export const getRoles = createAsyncThunk('role/getRoles', async () => {
    let data;
    try {
        const response = await axios.get(`/roles`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getSeasonOccurences = createAsyncThunk('enum/getSeasonOccurences', async () => {
    try {
        const response = await axios.get(`/enums/seasonOccurences`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getBranchCashBoxStatus = createAsyncThunk('enum/getBranchCashBoxStatus', async () => {
    try {
        const response = await axios.get(`/enums/branchCashboxStatus`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getBranchCashBoxTypes = createAsyncThunk('enum/getBranchCashBoxTypes', async () => {
    try {
        const response = await axios.get(`/enums/branchCashboxTypes`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getReservationPaymentTypes = createAsyncThunk('enum/reservationPaymentTypes', async () => {
    try {
        const response = await axios.get(`/enums/reservationPaymentTypes`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getCustomerTypeID = createAsyncThunk('enum/getCustomerTypeID', async () => {
    try {
        const response = await axios.get(`/enums/customerIdentityTypes`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getFinancialSource = createAsyncThunk('enum/getFinancialSource', async () => {
    try {
        const response = await axios.get(`/enums/financialSources`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getBankAccountTypes = createAsyncThunk('enum/getBankAccountTypes', async () => {
    try {
        const response = await axios.get(`/enums/bankAccountTypes`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getBankAccountStatus = createAsyncThunk('enum/getBankAccountStatus', async () => {
    try {
        const response = await axios.get(`/enums/bankAccountStatus`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getReservationStatus = createAsyncThunk('enum/reservationStatus', async () => {
    try {
        const response = await axios.get(`/enums/reservationStatus`);
        let { data } = await response.data;
        data = Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'approved' && key !== 'inProgress'));
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getExpenseTypes = createAsyncThunk('enum/expenseStatus', async () => {
    try {
        const response = await axios.get(`/enums/expenseStatus`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getExpenseConfigTypes = createAsyncThunk('enum/getExpenseConfigTypes', async () => {
    try {
        const response = await axios.get(`/enums/expenseConfigTypes`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const getExpenseOccurrences = createAsyncThunk('enum/getExpenseOccurrences', async () => {
    try {
        const response = await axios.get(`/enums/expenseOccurrences`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
const slice = createSlice({
    name: 'enum',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRoles.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.roles = action.payload;
        })
            .addCase(getRoles.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getExpenseConfigTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpenseConfigTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expenseConfigTypes = action.payload;
        })
            .addCase(getExpenseConfigTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            //------
            .addCase(getExpenseOccurrences.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpenseOccurrences.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expenseConfigOccurrences = action.payload;
        })
            .addCase(getExpenseOccurrences.rejected, (state, action) => {
            state.status = 'failed';
        })
            //-----
            .addCase(getSeasonOccurences.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getSeasonOccurences.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.seasonOccurences = action.payload;
        })
            .addCase(getSeasonOccurences.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getReservationPaymentTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getReservationPaymentTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.paymentTypes = action.payload;
        })
            .addCase(getReservationPaymentTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getReservationStatus.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getReservationStatus.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.reservationStatus = action.payload;
        })
            .addCase(getReservationStatus.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getExpenseTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpenseTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expenseStatus = action.payload;
        })
            .addCase(getExpenseTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getCustomerTypeID.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getCustomerTypeID.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.customerIdType = action.payload;
        })
            .addCase(getCustomerTypeID.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBankAccountTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBankAccountTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const bankAccountTypesObject = Object.keys(action.payload).map((key) => action.payload[key]);
            const bankAccountType = Object.keys(bankAccountTypesObject).map((key) => bankAccountTypesObject[key]);
            state.bankAccountTypes = bankAccountType;
        })
            .addCase(getBankAccountTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            //------
            .addCase(getBranchCashBoxStatus.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranchCashBoxStatus.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const branchCashBoxStatusObject = Object.keys(action.payload).map((key) => action.payload[key]);
            state.branchCashBoxStatus = branchCashBoxStatusObject;
        })
            .addCase(getBranchCashBoxStatus.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBranchCashBoxTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBranchCashBoxTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const branchCashBoxTypesObject = Object.keys(action.payload).map((key) => action.payload[key]);
            const branchCashBoxType = Object.keys(branchCashBoxTypesObject).map((key) => branchCashBoxTypesObject[key]);
            state.branchCashBoxTypes = branchCashBoxType;
        })
            .addCase(getBranchCashBoxTypes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getFinancialSource.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getFinancialSource.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const financialSourceObject = Object.keys(action.payload).map((key) => action.payload[key]);
            const financialSource = Object.keys(financialSourceObject).map((key) => financialSourceObject[key]);
            state.financialSources = financialSource;
        })
            .addCase(getFinancialSource.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBankAccountStatus.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBankAccountStatus.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const bankAccountStatusObject = Object.keys(action.payload).map((key) => action.payload[key]);
            const bankAccountStatus = Object.keys(bankAccountStatusObject).map((key) => bankAccountStatusObject[key]);
            state.bankAccountStatus = bankAccountStatus;
        })
            .addCase(getBankAccountStatus.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
